/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

interface MapInfo {
    center: [number, number];
    zoomlevel: number;
    mapWidth: string | number;
    mapHeight: string | number;
}
type MapObject = L.Map;

export default function LFMapAddLayer() {
    const mapRef = useRef<HTMLDivElement>(null);
    const [mapObject, setMapObject] = useState<MapObject>();
    const [mapInfo] = useState<MapInfo>({
        center: [37.5666612, 126.9783785],  // 서울시청 경도와 위도
        zoomlevel: 13,
        mapWidth: '100%',
        mapHeight: '75%',
    });
    
    // 레이어 생성
    const makeGEOJSONLayer = (map: L.Map, data: GeoJSON.GeoJsonObject): L.Layer => {
        // GeoJSON 레이어를 지도에 추가
        return L.geoJSON(data).addTo(map);
    };
    

    // 레이어 리스트를 화면 가운데로
    const moveMapToLayer = (map: L.Map, layers: Array<L.Layer>): void => {
        // GeoJSON 레이어를 지도에 추가
        let bbox: L.LatLngBounds | null = null;
        layers.forEach((layer)=>{
            if (layer instanceof L.GeoJSON) {
                if (!bbox) {
                    bbox = layer.getBounds();
                } else {
                    bbox.extend(layer.getBounds());
                }
            }
        });
        if(bbox) map.fitBounds(bbox);
        
    };

    

    useEffect(() => {
        console.log(mapObject);
        // 배경지도 초기 세팅!
        const tile_layer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
        const map = new L.Map(mapRef.current || '');
        map.setView(L.latLng(mapInfo.center[0], mapInfo.center[1]), mapInfo.zoomlevel);
        map.addLayer(tile_layer);

        // 지도 객체 저장
        setMapObject(map);

        fetch('/geojsons/polygon_4326.geojson').then(res => res.json()).then(data => {
            const polygonLayer = makeGEOJSONLayer(map, data);
            const layers = [];
            layers.push(polygonLayer)
            moveMapToLayer(map,layers);
        });

        return () => {
            map.remove();
        };
    }, []);



    // const makeWMSLayer(() =>{
        
    // });
    
    // const makeWKTLayer(() =>{

    // });

//   const setStyle(()=> {

//   });

//   const setPopup(()=>{

//   });

//   const setClickEvent(()=>{

//   });

    return <div ref={mapRef} style={{width: '100%', height: '100%'}}/>
}
