import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface LatestPositionState {
  center: [number, number];
  zoom: number;
  setCenter: (center: [number, number]) => void;
  setZoom: (zoom: number) => void;
}

export const useLatestPositionStore = create(
  persist<LatestPositionState>(
    (set) => ({
      center: [14133447.695112107, 4513703.814602685],
      zoom: 15,
      setCenter: (center: [number, number]) => set({ center }),
      setZoom: (zoom: number) => set({ zoom }),
    }),
    {
      name: 'latest-position',
      storage: createJSONStorage(() => localStorage),
    }
  )
)

export default useLatestPositionStore;