/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { Cluster, Vector, XYZ } from "ol/source";
import { fromLonLat } from "ol/proj";
import { Circle, Fill, Stroke, Style, Text } from "ol/style";
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { StyleLike } from 'ol/style/Style';
import { Point } from "ol/geom";


export default function OLMapBasic(){
  const map_ref = useRef<HTMLDivElement>(null)
  const styleCache = useMemo<{ [key: number]: Style }>(() => ({}), []);

  const getStyle = useCallback((feature: Feature) => {
    const size = feature.get('features').length;
    let style = styleCache[size];
    if (!style) {
      // eslint-disable-next-line no-nested-ternary
      // const color = size > 25 ? "192,0,0" : size > 8 ? "255,128,0" : "0,128,0";
      const radius = Math.max(8, Math.min(size * 0.75, 20));
      // const dash1 = 2 * Math.PI * radius / 6;
      // const dash = [0, dash1, dash1, dash1, dash1, dash1, dash1];
      style = new Style({
        image: new Circle({
          radius,
          stroke: new Stroke({
            // color: `rgba(${color},0.5)`,
            width: 15,
            // lineDash: dash,
            // lineCap: "butt"
          }),
          fill: new Fill({
            // color: `rgba(${color},1)`
          })
        }),
        text: new Text({
          text: size.toString(),
          // font: 'bold 12px comic sans ms',
          // textBaseline: 'top',
          fill: new Fill({
            color: '#fff'
          })
        })
      });
      styleCache[size] = style;
    }
    return style;
  }, [styleCache]);

  useEffect(() => {
      const tile_layer = new TileLayer({
        source: new XYZ({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        }),
      });

      const view = new View({
        // 서울시청 경도와 위도
        center: fromLonLat([126.9783785, 37.5666612]), 
        zoom: 14
      })

      // Cluster Source
      const clusterSource = new Cluster({
        distance: 40,
        source: new Vector()
      });

      // Animated cluster layer
      const clusterLayer = new AnimatedCluster({
        source: clusterSource,
        animationDuration: 700,
        // Cluster style
        style: getStyle as StyleLike
      });

      const map = new Map();

      map.setLayers([tile_layer,clusterLayer]);
      map.setView(view);
      map.setTarget(map_ref.current || '')

      const ext = map.getView().calculateExtent(map.getSize());
      const features:Feature[]=[];
      for (let i=0; i<200; i+=1){
        features[i]=new Feature(new Point([ext[0]+(ext[2]-ext[0])*Math.random(), ext[1]+(ext[3]-ext[1])*Math.random()]));
        features[i].set('id',i);
      }
      clusterSource.getSource()!.clear();
      clusterSource.getSource()!.addFeatures(features);

      return () => {
          map.setTarget('');
      };

  }, []);

    return <div ref={map_ref} style={{width: '100%', height: '100%'}}/>
}