/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import {LeafletMap, OLMap, InnopamMap} from "@innopam/map";
import "leaflet/dist/leaflet.css";

export default function IPMapBasic(){
  const leaflet_map_ref = useRef<HTMLDivElement>(null)
  const leaflet_map = useRef<InnopamMap>()

  const ol_map_ref = useRef<HTMLDivElement>(null)
  const ol_map = useRef<InnopamMap>()

  // const map_ref = useRef<HTMLDivElement>(null)
  // const map = useRef<InnopamMap>()

  useEffect(() => {
    if (!leaflet_map.current) {
      // eslint-disable-next-line no-new
      leaflet_map.current = new LeafletMap(leaflet_map_ref.current || '');
      leaflet_map.current?.addXYZLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png');
      leaflet_map.current?.setViewByLatLng([37.5666612, 126.9783785], 14);
    }

    if (!ol_map.current) {
      ol_map.current = new OLMap(ol_map_ref.current || '');
      ol_map.current?.addXYZLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png');
      ol_map.current?.setViewByLatLng([37.5666612, 126.9783785], 14);
    }

    // if (!map.current) {
    //  map.current = new LeafletMap(map_ref.current || '');
    //  map.current = new OLMap(map_ref.current || '');
      
    //   map.current?.addXYZLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png');
    //   map.current?.setViewByLatLng([37.5666612, 126.9783785], 14);
    // }
    
  }, []);

  // return <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row'}}>
  //   <div ref={map_ref} style={{width: '50%', height: '100%'}}/>
  // </div>
  return <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row'}}>
    <div ref={leaflet_map_ref} style={{width: '50%', height: '100%'}}/>
    <div ref={ol_map_ref} style={{width: '50%', height: '100%'}}/>
  </div>

}