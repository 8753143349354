/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
// import { OSM } from "ol/source";
import { XYZ,  } from "ol/source";
import { fromLonLat } from "ol/proj";
// import { defaults } from 'ol/control/defaults';


export default function OLSyncMap(){
    const map1_ref = useRef<HTMLDivElement>(null)
    const map2_ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      
        const tile_layer1 = new TileLayer({
          // source: new OSM()
          source: new XYZ({
            // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
          }),
        });

        const tile_layer2 = new TileLayer({
          // source: new OSM()
          source: new XYZ({
            url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
            // url: 'https://api.maptiler.com/maps/satellite/{z}/{x}/{y}.jpg?key=get_your_own_D6rA4zTHduk6KOKTXzGB'
          }),
        });

        const view = new View({
            center: fromLonLat([126.9783785, 37.5666612]), // 서울시청 경도와 위도
            zoom: 14
        })

        const map1 = new Map();
        map1.setLayers([tile_layer1]);
        map1.setView(view);
        map1.setTarget(map1_ref.current || '')

        const map2 = new Map();
        map2.setLayers([tile_layer2]);
        map2.setView(view);
        map2.setTarget(map2_ref.current || '')
        
        return () => {
            map1.setTarget('');
            map2.setTarget('');
        };

    }, []);

    return <div style={{ display: "flex", height: "100%", width: "100%" }}>
      <div ref={map1_ref} style={{ flex: 1, height: "100%", width: "50%" }}></div>
      <div ref={map2_ref} style={{ flex: 1, height: "100%", width: "50%" }}></div>
    </div>
}