import React from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./layouts/MainLayout/MainLayout";
import ExplainLoginPage from "./pages/explains/auth/ExplainLoginPage";

// Leaflet Pages
import LFMapBasicPage from "./pages/leaflet/01/LFMapBasicPage";
import LFSyncMapPage from "./pages/leaflet/02/LFSyncMapPage";
import LFDrawMapPage from "./pages/leaflet/03/LFDrawMapPage";
import LFMapBasicAreaPage from "./pages/leaflet/04/LFMapBasicAreaPage";
import LFMapBasicInfoPage from "./pages/leaflet/05/LFMapBasicInfoPage";
import LFMapBasicMovePage from "./pages/leaflet/06/LFMapBasicMovePage";
import LFMouseEventPage from "./pages/leaflet/07/LFMouseEventPage";
import LFMapAddLayer from "./pages/leaflet/08/LFMapAddLayerPage";
// import LFMapLayerAction from "./pages/leaflet/09/LFMapLayerActionPage";

// Innopam Pages
import IPMapBasicPage from "./pages/innopam/IPMapBasicPage";
import IPSyncLFMapPage from "./pages/innopam/IPSyncLFMapPage";
import IPSyncOLMapPage from "./pages/innopam/IPSyncOLMapPage";

// Openlayers Pages
import OLMapBasicPage from "./pages/openlayers/01/MapBasicPage";
import OLSyncMapPage from "./pages/openlayers/02/SyncMapPage";
import OLDrawAndModifyPage from "./pages/openlayers/03/DrawAndModifyPage";
import OLRememberLatestPositionPage from "./pages/openlayers/04/RememberLatestPositionPage";
import OLSaveGeoJsonPage from "./pages/openlayers/05/SaveGeoJsonPage";
import OLControlSwipePage from "./pages/openlayers/06/ControlSwipePage";
import OLSynchronizeMapPage from "./pages/openlayers/07/SynchronizeMapPage";
import OLBoxSelectionPage from "./pages/openlayers/08/BoxSelectionPage";
import OLPhotoPage from "./pages/openlayers/09/PhotoPage";
import OLAnimatedClusterPage from "./pages/openlayers/10/AnimatedClusterPage";
import OLPhotoClusterPage from "./pages/openlayers/11/PhotoClusterPage";

export default function App() {
  return (
    <div style={{ height: "100vh" }}>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index path="login" element={<ExplainLoginPage />} />
          <Route path="/map">
            <Route path="openlayers">
              <Route path="basic" element={<OLMapBasicPage />} />
              <Route path="sync-map" element={<OLSyncMapPage />} />
              <Route path="draw-and-modify-features" element={<OLDrawAndModifyPage />} />
              <Route path="remember-latest-position" element={<OLRememberLatestPositionPage />} />
              <Route path="save-geojson" element={<OLSaveGeoJsonPage />} />
              <Route path="control-swipe" element={<OLControlSwipePage />} />
              <Route path="sync-map2" element={<OLSynchronizeMapPage />} />
              <Route path="box-selection" element={<OLBoxSelectionPage />} />
              <Route index path="photo" element={<OLPhotoPage />} />
              <Route index path="animated-cluster" element={<OLAnimatedClusterPage />} />
              <Route index path="photo-cluster" element={<OLPhotoClusterPage />} />
            </Route>
            <Route path="leaflet">
              <Route index path="basic" element={<LFMapBasicPage />} />
              <Route index path="sync-map" element={<LFSyncMapPage />} />
              <Route index path="draw-map" element={<LFDrawMapPage />} />
              <Route index path="basic-area" element={<LFMapBasicAreaPage />} />
              <Route index path="basic-info" element={<LFMapBasicInfoPage />} />
              <Route index path="basic-move" element={<LFMapBasicMovePage />} />
              <Route index path="mouse-event" element={<LFMouseEventPage />} />
              <Route index path="add-layer" element={<LFMapAddLayer />} />
              {/* <Route index path="layer-action" element={<LFMapLayerAction />} /> */}
            </Route>
            
            <Route path="innopam">
            <Route index path="basic" element={<IPMapBasicPage />} />
            <Route index path="sync-lf-map" element={<IPSyncLFMapPage />} />
            <Route index path="sync-ol-map" element={<IPSyncOLMapPage />} />
            </Route>
          </Route>
        </Route>
        {/* <Route path="/examples" element={<EmptyLayout />}>
          <Route path="map">
            <Route path="leaflet">
              <Route index path="basic" element={<LFMapBasicPage />} />
              <Route index path="sync-map" element={<LFSyncMapPage />} />
            </Route>
            <Route path="openlayers">
              <Route index path="basic" element={<OLMapBasicPage />} />
            </Route>
          </Route>

          <Route index path="login" element={<LoginPage />} />
        </Route>
        <Route path="/" element={<EmptyLayout />}>
          <Route path="*" element={<NoMatch />} />
        </Route> */}
      </Routes>
    </div>
  );
}
