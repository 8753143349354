import React from "react";
import { Box, Paper, Toolbar, Typography } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax, import/no-duplicates
import LFMapBasicMoveCode from "!!raw-loader!./code/LFMapBasicMove";
// eslint-disable-next-line import/no-duplicates
import LFMapBasicMove from "./code/LFMapBasicMove";

const Component = () => {
  return (
    <SyntaxHighlighter
      language="typescript"
      style={oneDark}
      wrapLongLines={true}
      lineProps={{ style: { height: "40vh" } }}
    >
      {LFMapBasicMoveCode}
    </SyntaxHighlighter>
  );
};

const install_code = 
`yarn add leaflet
# or
npm install leaflet
`

const InstallCode = ()=>{
  return (
    <SyntaxHighlighter
      language="typescript"
      style={oneDark} 
      wrapLongLines={true}
    >
      {install_code}
    </SyntaxHighlighter>
  );
}

export default function LFMapBasicMovePage() {
  return (
    <Paper sx={{ height: "100vh", width: "100%", px: 1 }}>
      <Toolbar />
      <Typography component="h1" variant="h4" sx={{ py: 2 }}>
        지도-기본 이동
      </Typography>

      <Box sx={{ height: "40vh", width: "100%" }}>
        <LFMapBasicMove />
      </Box>

      <Box sx={{ }}>
        <Typography variant="h6">설치</Typography>
        <InstallCode />
      </Box>

      <Box sx={{}}>
        <Typography variant="h6">코드</Typography>
        <Component />
      </Box>
    </Paper>
  );
}
