import React from "react";
import { Box, Paper, Toolbar, Typography } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax, import/no-duplicates
import LoginFormCode from "!!raw-loader!../../../codes/auth/Login/LoginForm.tsx";
// eslint-disable-next-line import/no-duplicates
import LoginForm from "../../../codes/auth/Login/LoginForm";

const Component = () => {
  return (
    <SyntaxHighlighter
      language="typescript"
      style={oneDark}
      wrapLongLines={true}
      lineProps={{ style: { height: "40vh" } }}
    >
      {LoginFormCode}
    </SyntaxHighlighter>
  );
};

export default function ExplainLoginPage() {
  return (
    // <Container component="main" maxWidth="lg" sx={{ height:'100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
    <Paper sx={{ height: "100vh", width: "100%", px: 1 }}>
      {/* <Typography variant="h6">로그인</Typography> */}
      <Toolbar />
      <Typography component="h1" variant="h4" sx={{ py: 2 }}>
        로그인 페이지
      </Typography>

      <Box sx={{ px: 2, mb: 5, border: "1px solid #000" }}>
        <LoginForm />
      </Box>

      <Box sx={{ height: "40vh" }}>
        <Component />
      </Box>
    </Paper>
    // </Container>
  );
}
