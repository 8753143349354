/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L, { LatLngBounds } from "leaflet";


interface MapInfo {
    center: [number, number];
    bounds: LatLngBounds | undefined;
    zoomlevel: number;
    mapWidth: string | number;
    mapHeight: string | number;
    searchOn: boolean;
}
type MapObject = L.Map;


export default function LFMapBasicInfo() {
    const mapRef = useRef<HTMLDivElement>(null);
    const [mapObject, setMapObject] = useState<MapObject>();
    const [mapInfo, setMapInfo] = useState<MapInfo>({
        center: [37.5666612, 126.9783785],  // 서울시청 경도와 위도
        bounds: undefined as unknown as LatLngBounds,
        zoomlevel: 13,
        mapWidth: '100%',
        mapHeight: '75%',
        searchOn: false
    });
    

    useEffect(() => {
        
        // 배경지도 초기 세팅!
        const tile_layer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
        const map = new L.Map(mapRef.current||'');
        map.setView(L.latLng(mapInfo.center[0],mapInfo.center[1]), 13);
        map.addLayer(tile_layer);
        
        // 지도 객체 저장
        setMapObject(map);
        
        return () => {
            map.remove();
        };
        
    }, []); 
    
    // 정보가져오는 함수
    const getInfo = (): void => {
        if(mapObject){
            setMapInfo(prev => ({ ...prev, center: [mapObject.getCenter().lat,mapObject.getCenter().lng]}));
            setMapInfo(prev => ({ ...prev, bounds: mapObject.getBounds()}));
            setMapInfo(prev => ({ ...prev, zoomlevel: mapObject.getZoom()}));
            setMapInfo(prev => ({ ...prev, searchOn: true}));
        }
    }

    return (
        <div style={{width: '100%', height: '100%'}}>
            <div ref={mapRef} style={{width: mapInfo.mapWidth, height: mapInfo.mapHeight}}></div>
            {mapInfo.searchOn?
            <div style={{width: '100%', height: '25%', backgroundColor: '#fff', zIndex: 10}}>
                <p style={{margin: 5}}><span>중심점 : </span>{ mapInfo.center.toString() }</p>
                <p style={{margin: 5}}><span>테두리 : </span> {mapInfo.bounds ? mapInfo.bounds.toBBoxString() : ''}</p>
                <p style={{margin: 5}}><span>zoomlevel : </span> {mapInfo.zoomlevel}</p>
                <button onClick={getInfo}>정보가져오기</button>
            </div>
            : <button onClick={getInfo}>정보가져오기</button>
            }
        </div>
    )
}
