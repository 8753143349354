import React from "react";
import { Box, Paper, Toolbar, Typography } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax, import/no-duplicates
import OLMapBasicCode from "!!raw-loader!./code/PhotoCluster";
// eslint-disable-next-line import/no-duplicates
import OLMapBasic from "./code/PhotoCluster";

const Component = () => {
  return (
    <SyntaxHighlighter
      language="typescript"
      style={oneDark}
      wrapLongLines={true}
      lineProps={{ style: { height: "40vh" } }}
    >
      {OLMapBasicCode}
    </SyntaxHighlighter>
  );
};

const install_code = 
`yarn add ol
# or
npm install ol
`

const InstallCode = ()=>{
  return (
    <SyntaxHighlighter
      language="typescript"
      style={oneDark}
      wrapLongLines={true}
    >
      {install_code}
    </SyntaxHighlighter>
  );
}

export default function OLPhotoClusterPage() {
  return (
    <Paper sx={{ height: "100vh", width: "100%", px: 1 }}>
      <Toolbar />
      <Typography component="h1" variant="h4" sx={{ py: 2 }}>
      Openlayers / 포토 클러스터
      </Typography>

      <Box sx={{ height: "40vh", width: "100%" }}>
        <OLMapBasic />
      </Box>

      <Box sx={{ }}>
        <Typography variant="h6">설치</Typography>
        <InstallCode />
      </Box>

      <Box sx={{}}>
        <Typography variant="h6">코드</Typography>
        <Component />
      </Box>
    </Paper>
  );
}
