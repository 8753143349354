import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import GroupMenu from "./GroupMenu";
import menu_groups from "../../../menu_groups";

export interface MenuItem {
  key: string;
  icon?: React.ReactNode;
  text: string;
  path: string;
  ready?: boolean;
}



export default function MainDrawerList() {
  const MainDrawerList = (
    <Box role="presentation" sx={{ width: 250 }}>
      {/* <AuthGroupMenu /> */}
      {menu_groups.map((menu) => (
        <GroupMenu key={menu.group_name} {...menu} />
      ))}
      <Divider />
    </Box>
  );
  return MainDrawerList;
}
