/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

type MapObject = L.Map;

export default function LFMapBasic() {
    const mapRef = useRef<HTMLDivElement>(null);
    const [mapObject, setMapObject] = useState<MapObject>();
    const baseMaps ={
        'openstreetmap': {
            'url':'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            'attribution':'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        },
        'vworld-Base': {
            'url':'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/Base/{z}/{y}/{x}.png',
            'attribution':'&copy; <a href="https://vworld.kr/copyright">VWorldMap</a> contributors'
        },
        'vworld-Satellite': {
            'url':'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/Satellite/{z}/{y}/{x}.jpeg',
            'attribution':'&copy; <a href="https://vworld.kr/copyright">VWorldMap</a> contributors'
        },
        'vworld-Hybrid': {
            'url':'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/Hybrid/{z}/{y}/{x}.png',
            'attribution':'&copy; <a href="https://vworld.kr/copyright">VWorldMap</a> contributors'
        },
        'vworld-White': {
            'url':'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/white/{z}/{y}/{x}.png',
            'attribution':'&copy; <a href="https://vworld.kr/copyright">VWorldMap</a> contributors'
        },
        'vworld-Midnight': {
            'url':'http://api.vworld.kr/req/wmts/1.0.0/48494331-A4C4-380A-9A01-F25957423F15/midnight/{z}/{y}/{x}.png',
            'attribution':'&copy; <a href="https://vworld.kr/copyright">VWorldMap</a> contributors'
        },
    };
    const [baseTile, setBaseTile] = useState('openstreetmap');

    useEffect(() => {
        const center = L.latLng(37.5666612, 126.9783785); // 서울시청 경도와 위도
        
        // 지도 생성
        const map = new L.Map(mapRef.current||'');
        // 중심과 zoomlevel 설정
        map.setView(center, 13);


        // 배경지도 레이어 생성
        const tLayerInfo = baseMaps[baseTile as keyof typeof baseMaps];
        const tLayer = L.tileLayer(tLayerInfo.url, {
                attribution: tLayerInfo.attribution,
                className: 'baseLayer'
        });
        // // 지도에 레이어 추가
        map.addLayer(tLayer);
        
        // 지도 객체 저장
        setMapObject(map);
        
        
        return () => {
            map.remove();
        };
        
    }, []);

    useEffect(() => {
        if(!mapObject) return;
        mapObject.eachLayer((layer)=>{
            if('className' in layer.options && layer.options.className === 'baseLayer') {
                (layer as L.TileLayer).setUrl(baseMaps[baseTile as keyof typeof baseMaps].url);
            }
        });
    }, [baseTile]);

    return (
        <div ref={mapRef} style={{width: '100%', height: '100%'}}>
            <div style={{width:'150px', minHeight:'100px', backgroundColor:'#fff', right:10, top:10, zIndex:999, position:"absolute"}}>
                {Object.keys(baseMaps).map((name) => (   
                    <p key={name}>
                        <label>
                            <input 
                                type="radio" 
                                name="baseMap" 
                                value={name} 
                                checked={baseTile === name}
                                onClick={() => setBaseTile(name)}
                            />
                            {name}
                        </label>
                    </p>
                ))}
            </div>
        </div>
    )
}
