/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { Cluster, XYZ } from "ol/source";
import VectorSource from "ol/source/Vector";
import { GeoJSON } from 'ol/format';
import { Fill, RegularShape, Stroke, Style, Text } from "ol/style";
import Photo from "ol-ext/style/Photo";
import AnimatedCluster from "ol-ext/layer/AnimatedCluster";
import { StyleLike } from "ol/style/Style";

export default function PhotoCluster(){
  const map_ref = useRef<HTMLDivElement>(null)

  const styleCache = useRef<{ [key: string]: Style }>({});
  const numStyleCache = useRef<{ [key: number]: Style }>({});

  const shape = useMemo(() => new RegularShape({
    points: 120,
    radius: 10,
    displacement: [20,20],
    fill: new Fill({
      color: '#D64D00'
    })
  }), []);

  const getStyle = useCallback((feature: Feature) => {	
    const f = feature.get("features")[0];
    const nb = feature.get("features").length;
    const th = f.get("img");
    // const k = th.replace(/(.*)\/(.*)\?(.*)/,"$2")+(nb>1?"_0":"_1")+(sel==='sel'?"_1":"");
    const k = th.replace(/(.*)\/(.*)\?(.*)/,"$2")+(nb>1?"_0":"_1")
    
    let ret: Style[] = [];
    let count = null;
    if(numStyleCache.current[nb]){
      count = numStyleCache.current[nb];
    } else if (nb>1){
      
      count = new Style({
        image: shape,
        text: new Text({
          text: nb>99?"99+":nb.toString(),
          font: `bold ${nb>99?10:12}px helvetica,sans-serif`,
          offsetX: 20,
          offsetY: -20,
          fill: new Fill({
            color: '#FFF'
          })
        })
      });
      numStyleCache.current[nb] = count;
    }

    if(count){
      ret = [count];
    }
    
    if (!styleCache.current[k]) {
      const style = new Style({
        image: new Photo ({
          src: th,
          radius: 20,
          crop: true,
          kind: (nb>1) ? "folio":"square",
          // onload() { vector.changed(); },
          stroke: new Stroke({
            width: 2,
            color: '#fff'
          })
        })
      });
      styleCache.current[k] = style;
    }

    ret = [styleCache.current[k], ...ret];

    return ret;
  }, [styleCache, numStyleCache, shape]);
  
  useEffect(() => {

      const tile_layer = new TileLayer({
        source: new XYZ({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        }),
      });


    const source = new VectorSource({
      url: '/geojsons/paris-1900.geojson',
      format: new GeoJSON(),
    });

    const clusterSource = new Cluster({
      distance: 60,
      source
    });

    // Animated cluster layer
    const clusterLayer = new AnimatedCluster({
      source: clusterSource,
      animationDuration: 700,
      // Cluster style
      style: getStyle as StyleLike
    });
    
    // const vectorLayer = new VectorLayer({
    //   source,
    //   style: 
    // })

      const view = new View({
        // 서울시청 경도와 위도
        zoom: 15,
        center: [261204.43490751847, 6250258.191535994]
      })

      const map = new Map();

      map.setLayers([tile_layer,clusterLayer]);
      map.setView(view);
      map.setTarget(map_ref.current || '')

      return () => {
          map.setTarget('');
      };

  }, [getStyle]);

    return <div ref={map_ref} style={{width: '100%', height: '100%'}}/>
}