/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L, { LatLng } from "leaflet";


interface MapInfo {
    center: [number, number];
    zoomlevel: number;
    mapWidth: string | number;
    mapHeight: string | number;
    moveBounds: string;
    movePointLat: number;
    movePointLng: number;
    searchOption: string;
}
type MapObject = L.Map;


export default function LFMapBasicInfo() {
    const mapRef = useRef<HTMLDivElement>(null);
    const [mapObject, setMapObject] = useState<MapObject>();
    const [mapInfo, setMapInfo] = useState<MapInfo>({
        center: [37.5666612, 126.9783785],  // 서울시청 경도와 위도
        zoomlevel: 13,
        mapWidth: '100%',
        mapHeight: '75%',
        moveBounds: '',
        movePointLat: 37.5666612,
        movePointLng: 126.9783785,
        searchOption: 'point'
    });


    useEffect(() => {

        // 배경지도 초기 세팅!
        const tile_layer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
        const map = new L.Map(mapRef.current || '');
        map.setView(L.latLng(mapInfo.center[0], mapInfo.center[1]), mapInfo.zoomlevel);
        map.addLayer(tile_layer);

        // 지도 객체 저장
        setMapObject(map);

        return () => {
            map.remove();
        };

    }, []);


    // point가 중심에 오게 지도를 움직이는 함수-1 (move)
    const moveFlyToMap = (): void => {
        if (mapObject) {
            const latLng: LatLng = new L.LatLng(mapInfo.movePointLat, mapInfo.movePointLng);
            mapObject.flyTo(latLng);
        }
    }
    // point가 중심에 오게 지도를 움직이는 함수-2 (move)
    const movePanToMap = (): void => {
        if (mapObject) {
            const latLng: LatLng = new L.LatLng(mapInfo.movePointLat, mapInfo.movePointLng);
            mapObject.panTo(latLng);
        }
    }
    // point가 중심에 오게 지도를 움직이는 함수-3 (move)
    const moveSetViewMap = (): void => {
        if (mapObject) {
            const latLng: LatLng = new L.LatLng(mapInfo.movePointLat, mapInfo.movePointLng);
            // const point: Point = new L.Point(mapInfo.movePointLat, mapInfo.movePointLng);
            mapObject.setView(latLng,15);
        }
    }
    // bound가 중심에 오게 지도를 움직이는 함수-1 (move+zoom)
    const moveFitBoundMap = (): void => {
        if (mapObject) {
            if(mapInfo.moveBounds?.length > 0){
                const strArr: string[] = mapInfo.moveBounds?.split(',');
                const minLatLng = L.latLng(Number(strArr[1]), Number(strArr[0]));
                const maxLatLng = L.latLng(Number(strArr[3]), Number(strArr[2]));
                const bound = L.latLngBounds(minLatLng, maxLatLng);
                mapObject.fitBounds(bound);
            }
        }
    }
    // bound가 중심에 오게 지도를 움직이는 함수-2 (move+zoom)
    const moveFlyToBoundsMap = (): void => {
        if (mapObject) {
            if(mapInfo.moveBounds?.length > 0){
                const strArr: string[] = mapInfo.moveBounds?.split(',');
                const minLatLng = L.latLng(Number(strArr[1]), Number(strArr[0]));
                const maxLatLng = L.latLng(Number(strArr[3]), Number(strArr[2]));
                const bound = L.latLngBounds(minLatLng, maxLatLng);
                mapObject.flyToBounds(bound);
            }
        }
    }

    const changeMenu = (event: React.MouseEvent<HTMLInputElement>): void =>{
        const val: string = event.currentTarget.textContent||'point';
        setMapInfo(prev => ({ ...prev, searchOption: val}))
        
    }
    
    const changeLat = (event: React.FormEvent<HTMLInputElement>): void => {
        const val:number = Number(event.currentTarget.value);
        setMapInfo(prev => ({ ...prev,  movePointLat: val}));
    }
    
    const changeLng = (event: React.FormEvent<HTMLInputElement>): void => {
        const val:number = Number(event.currentTarget.value);
        setMapInfo(prev => ({ ...prev,  movePointLng: val}));
    }
    
    const changeBounds = (event: React.FormEvent<HTMLInputElement>): void => {
        const val:string = event.currentTarget.value;
        if(val){
            //  "123,123,123,123" 이런식으로 넣어야함.
            setMapInfo(prev => ({ ...prev,  moveBounds: val}));
        }        
    }
    

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div ref={mapRef} style={{ width: mapInfo.mapWidth, height: mapInfo.mapHeight }}></div>
            <div style={{ width: '100%', height: '25%', backgroundColor: '#fff', zIndex: 10 }}>
                <p> 
                    <span style={{border:'#DCDCDC solid', padding:'5px', cursor:'pointer', marginRight:'10px', background: mapInfo.searchOption==='point'?'#7FFFD4':'#C0C0C0'}} onClick={changeMenu}>point</span> 
                    <span style={{border:'#DCDCDC solid', padding:'5px', cursor:'pointer', background: mapInfo.searchOption==='bounds'?'#7FFFD4':'#C0C0C0'}} onClick={changeMenu}>bounds</span>
                    
                </p>
                {mapInfo.searchOption === 'point' ?
                    <div>
                        <p>
                            lat: <input value={mapInfo.movePointLat} onChange={changeLat} /> lng: <input value={mapInfo.movePointLng} onChange={changeLng} />
                            <button style={{ marginLeft:"5px" }} onClick={moveFlyToMap}>flyTo이동</button>
                            <button style={{ marginLeft:"5px" }} onClick={movePanToMap}>PanTo이동</button>
                            <button style={{ marginLeft:"5px" }} onClick={moveSetViewMap}>setView이동</button>
                        </p>
                    </div>
                    :
                    <div>
                        <p>
                            <input value={mapInfo.moveBounds} onChange={changeBounds} />
                            <button style={{ marginLeft:"5px" }}  onClick={moveFitBoundMap}>fitbound이동</button>
                            <button style={{ marginLeft:"5px" }}  onClick={moveFlyToBoundsMap}>flyToounds이동</button>
                        </p>
                        <p>예제: 126.85449600219728,37.53994954664835,127.10220336914064,37.59328762229771</p>
                    </div>
                }
            </div>
        </div>
    )
}
