/* eslint-disable camelcase */
import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.sync";

export default function LFSyncMap() {
  useEffect(() => {
    const map1 = L.map("map1").setView([51.505, -0.09], 13);
    const map2 = L.map("map2").setView([51.505, -0.09], 13);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map1);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map2);

    map1.sync(map2);
    map2.sync(map1);

    L.marker([51.5, -0.09]).addTo(map1);
    return () => {
      map1.remove();
      map2.remove();
    };
  }, []);

  return (
    <div style={{ display: "flex", height: "100%", width: "100%" }}>
      <div id="map1" style={{ flex: 1, height: "100%", width: "50%" }}></div>
      <div id="map2" style={{ flex: 1, height: "100%", width: "50%" }}></div>
    </div>
  );
}
