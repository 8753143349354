/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { XYZ,  } from "ol/source";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { GeoJSON } from 'ol/format';

import "ol/ol.css"
import "ol-ext/dist/ol-ext.css"
import Bar from "ol-ext/control/Bar";
import EditBar from "ol-ext/control/EditBar";
import UndoRedo from "ol-ext/interaction/UndoRedo";
import Button from "ol-ext/control/Button";
import Btn from '@mui/material/Button';
import './font-awesome.min.css';

const vector = new VectorLayer({
  source: new VectorSource({
    url: '/geojsons/point_4326.geojson',
    format: new GeoJSON()
  })
});

export default function OLDrawAndModifyFeatures(){
  const map = useRef<Map>(new Map())
  const mapRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    
      const tile_layer = new TileLayer({
        source: new XYZ({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        }),
      });

      const view = new View({
          center: [14133447.695112107, 4513703.814602685],
          zoom: 14,
      })

      map.current.setLayers([tile_layer, vector]);
      map.current.setView(view);
      map.current.setTarget(mapRef.current || '')

      const main_bar = new Bar();
      map.current.addControl(main_bar);
      // Editbar
      const editbar = new EditBar ({
        source: vector.getSource(),
        interactions: { Info: false }
      });
      main_bar.addControl(editbar);

      // Undo redo interaction
      const undoInteraction = new UndoRedo();
      map.current.addInteraction(undoInteraction);

      const bar = new Bar({ 
        group: true,
        controls: [
          new Button({
            html: '<i class="fa fa-undo" ></i>',
            title: 'undo...',
            handleClick() {
              undoInteraction.undo();
            }
          }),
          new Button({
            html: '<i class="fa fa-repeat" ></i>',
            title: 'redo...',
            handleClick() {
              undoInteraction.redo();
            }
          })
        ]
      });
      main_bar.addControl(bar);
      
      const mapInstance = map.current;
      return () => {
        if (mapInstance) {
          mapInstance.setTarget('');
        }
      };
  }, []);

    return <div style={{width: '100%', height: '90%'}}>
      <div ref={mapRef} style={{width: '100%', height: '100%'}}/>
      <Btn style={{width:'100%'}}
        variant="contained"
        onClick={() => {
          if(vector && vector.getSource()){
            const geojsonFormat = new GeoJSON();
            const features = vector.getSource()!.getFeatures()
            const geojsonData = geojsonFormat.writeFeatures(features, {
              featureProjection: 'EPSG:3857'
            });
            const element = document.createElement('a');
            const file = new Blob([geojsonData], { type: 'application/geo+json' });
            element.href = URL.createObjectURL(file);
            element.download = 'test.geojson';
            // Firefox requires the link to be in the body
            document.body.appendChild(element); 
            element.click();
            // Remove the element after downloading
            document.body.removeChild(element); 
        }
      }}>다운로드</Btn>
    </div>
}

