/* eslint-disable camelcase */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { OSM, XYZ } from "ol/source";
import "ol/ol.css"
import "ol-ext/dist/ol-ext.css"
import Swipe from "ol-ext/control/Swipe";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { GeoJSON } from 'ol/format';
import { Checkbox, FormControlLabel } from "@mui/material";

export default function OLDrawAndModifyFeatures(){
  
  const mapRef = useRef<HTMLDivElement>(null)
  const map = useMemo(()=>{
    return new Map()
  },[])

  const ctrl = useMemo(()=>{
    return new Swipe()
  },[])

  const [isHorizontal, setIsHorizontal] = useState(false)

  useEffect(()=>{
    ctrl.set('orientation', isHorizontal ? 'horizontal' : 'vertical')
  },[ctrl, isHorizontal])

  useEffect(() => {

    const layer = new TileLayer({
      source: new XYZ({
        url: 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}'
      }),
    });

    const osm = new TileLayer({
      source: new OSM() 
    });

    const vectorLayer = new VectorLayer({ 
      source: new VectorSource({
        url: '/geojsons/point_4326.geojson',
        format: new GeoJSON()
      })
    })

    const view = new View({
      center: [14094968.250525568,3930733.2959127575],
      zoom: 14,
    })
    
    map.setView(view);
    map.setTarget(mapRef.current || '')
    map.setLayers([layer, osm, vectorLayer]);
    
    // Control
    map.addControl(ctrl);
    ctrl.addLayer(layer,false);// Set layer on left
    ctrl.addLayer([osm], true); // OSM on right

    return () => {
      map.setTarget('');
    };
  }, []);

    return <div style={{width: '100%', height: '90%'}}>
    <div ref={mapRef} style={{width: '100%', height: '100%'}}/>
    <FormControlLabel control={<Checkbox onChange={(e)=>{
      setIsHorizontal(e.target.checked)
    }} />} label="가로로 나누기" />
  </div>
}

