import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { MenuItem } from "./MainDrawerList";

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default function GroupMenu({
  group_name,
  group_icon,
  menu_items,
}: {
  group_name: string;
  group_icon?: React.ReactNode;
  menu_items: MenuItem[];
}) {
  const [openAuth, setOpenAuth] = React.useState(false);
  const handleClick = () => {
    setOpenAuth(!openAuth);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        {group_icon && <ListItemIcon>{group_icon}</ListItemIcon>}
        <ListItemText primary={group_name} />
        {openAuth ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse unmountOnExit in={openAuth} timeout="auto">
        <List>
          {menu_items.map((item) => (
            <StyledLink
              to={item.ready ? item.path : "#"}
              key={item.key}
              style={{ textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                  <ListItemText
                    primary={item.ready ? item.text : `${item.text} (준비중)`}
                  />
                </ListItemButton>
              </ListItem>
            </StyledLink>
          ))}
        </List>
      </Collapse>

      <Divider />
    </>
  );
}
