/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import { fromLonLat } from "ol/proj";


export default function OLMapBasic(){
  const map_ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
      const tile_layer = new TileLayer({
        source: new XYZ({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        }),
      });

      const view = new View({
        // 서울시청 경도와 위도
        center: fromLonLat([126.9783785, 37.5666612]), 
        zoom: 14
      })

      const map = new Map();

      map.setLayers([tile_layer]);
      map.setView(view);
      map.setTarget(map_ref.current || '')

      return () => {
          map.setTarget('');
      };

  }, []);

    return <div ref={map_ref} style={{width: '100%', height: '100%'}}/>
}