/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";


interface MapInfo {
    center: [number, number];
    zoomlevel: number;
    mapWidth: string | number;
    mapHeight: string | number;
}
type MapObject = L.Map;


export default function LFMapBasicArea() {
    const mapRef = useRef<HTMLDivElement>(null);
    const [mapObject, setMapObject] = useState<MapObject>();
    const [mapInfo, setMapInfo] = useState<MapInfo>({
        center: [37.5666612, 126.9783785],  // 서울시청 경도와 위도
        zoomlevel: 13,
        mapWidth: '100%',
        mapHeight: '90%'
    });
    

    useEffect(() => {
        
        // 배경지도 레이어 생성
        const tile_layer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
        // 지도 생성
        const map = new L.Map(mapRef.current||'');
        // 중심과 zoomlevel 설정
        map.setView(L.latLng(mapInfo.center[0],mapInfo.center[1]), 13);
        // 지도에 레이어 추가
        map.addLayer(tile_layer);
        
        // 지도 객체 저장
        setMapObject(map);
        
        return () => {
            map.remove();
        };
        
    }, []); 
    

    const changeWidth = (event: React.FormEvent<HTMLInputElement>): void => {
        const val:string = event.currentTarget.value;
        setMapInfo(prev => ({ ...prev,  mapWidth: val}));
    }
    
    const changeHeight = (event: React.FormEvent<HTMLInputElement>): void => {
        const val:string = event.currentTarget.value;
        setMapInfo(prev => ({ ...prev,  mapHeight: val}));
    }
    // 정보가져오는 함수
    const setInfo = (): void => {
        if(mapObject){
            mapObject.invalidateSize();
        }
    }

    return (
        <div style={{width: '100%', height: '100%'}}>
            <div ref={mapRef} style={{width: mapInfo.mapWidth, height: mapInfo.mapHeight}}></div>
            <div>
                width: <input value={mapInfo.mapWidth} onChange={changeWidth} />, height: <input value={mapInfo.mapHeight} onChange={changeHeight} />
                <button onClick={setInfo}>설정 완료</button>
                <p style={{margin:'5px', color:'red'}}>* 설정 완료 누르면 지도 사이즈가 변하게 변경해야함.</p>
            </div>
        </div>
    )
}
