import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";

export default function LoginForm() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  return (
    <Box
      sx={{
        width: "100%", // 전체 너비 사용
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <BearCounter />
        <Controls /> */}
      <Box
        sx={{ m: 3, backgroundColor: "primary.main", p: 10, borderRadius: 1 }}
      >
        <Typography variant="h5" color="white">
          로고 위치
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <TextField
          required
          fullWidth
          autoFocus
          variant="filled"
          margin="normal"
          id="email"
          label="이메일"
          name="email"
          autoComplete="email"
          placeholder="example@domain.com"
          helperText="이메일 주소를 입력하세요."
          inputProps={{
            pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}",
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          required
          fullWidth
          variant="filled"
          margin="normal"
          name="password"
          label="비밀번호"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          로그인
        </Button>
        <Button fullWidth type="button" sx={{ mt: 1, mb: 2 }}>
          회원가입
        </Button>
      </Box>
    </Box>
  );
}
