/* eslint-disable camelcase */
import React, { useEffect, useMemo, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { GeoJSON } from 'ol/format';
import VectorLayer from "ol/layer/Vector";
import { Stroke, Style } from "ol/style";
import Photo from "ol-ext/style/Photo";

export default function OLMapBasic(){
  const map_ref = useRef<HTMLDivElement>(null)

  const styleCache = useMemo<{ [key: string]: Style }>(() => ({}), []);

  useEffect(() => {
    
      const tile_layer = new TileLayer({
        source: new XYZ({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        }),
      });


    const source = new VectorSource({
      url: '/geojsons/photo.geojson',
      format: new GeoJSON(),
    });
    
    const vectorLayer = new VectorLayer({
      source,
      style: (feature) => {

        const id = feature.get('id');
        if (styleCache[id]) {
          return styleCache[id];
        }

        const style = new Style({
          image: new Photo({
            src: feature.get('img'),
            radius: 20,
            crop: true,
            kind: 'circle',
            shadow: 5,
            displacement: [20,0],
            stroke: new Stroke({
              color: '#fff',
              width: 3,
            }),
            // onload: () => {
            //   console.log('loaded');
            // }
          }),
        }); 

        styleCache[id] = style;
        return style;
      },
    })

      const view = new View({
        // 서울시청 경도와 위도
        center: fromLonLat([126.9783785, 37.5666612]), 
        zoom: 14
      })

      const map = new Map();

      map.setLayers([tile_layer,vectorLayer]);
      map.setView(view);
      map.setTarget(map_ref.current || '')

      return () => {
          map.setTarget('');
      };

  }, []);

    return <div ref={map_ref} style={{width: '100%', height: '100%'}}/>
}