/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import { fromLonLat } from "ol/proj";
import Synchronize from "ol-ext/interaction/Synchronize"

export default function SynchronizeMap(){
  const map1_ref = useRef<HTMLDivElement>(null)
  const map2_ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const map1 = new Map({
      view: new View({
        // 서울시청 경도와 위도
        center: fromLonLat([126.9783785, 37.5666612]), 
        zoom: 14
      }),
      layers: [
        new TileLayer({
          source: new XYZ({url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'})
        })
      ]
    })

    const map2 = new Map({
      view: new View({
        // 서울시청 경도와 위도
        center: fromLonLat([126.9783785, 37.5666612]), 
        zoom: 14
      }),
      layers: [
        new TileLayer({
          source: new XYZ({url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'})
        })
      ]
    })

    map1.setTarget(map1_ref.current || '')
    map2.setTarget(map2_ref.current || '')

     // Synchronize the maps 
    map1.addInteraction( new Synchronize({ maps: [map2] }) );
    map2.addInteraction( new Synchronize({ maps: [map1] }) );

      return () => {
        map1.setTarget('');
        map2.setTarget('');
      };

  }, []);

    return<div style={{ display: "flex", height: "100%", width: "100%" }}>
    <div ref={map1_ref} style={{ flex: 1, height: "100%", width: "50%" }}></div>
    <div ref={map2_ref} style={{ flex: 1, height: "100%", width: "50%" }}></div>
  </div>
}