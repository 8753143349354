/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L, { LatLngBounds } from "leaflet";


interface MapInfo {
    center: [number, number];
    bounds: LatLngBounds | undefined;
    zoomlevel: number;
    mapWidth: string | number;
    mapHeight: string | number;
    mType: string | undefined;
    point: [number, number] | undefined;
}
type MapObject = L.Map;

export default function LFMouseEvent() {
    const mapRef = useRef<HTMLDivElement>(null);
    const [mapObject, setMapObject] = useState<MapObject>();
    const [mapInfo, setMapInfo] = useState<MapInfo>({
        center: [37.5666612, 126.9783785],  // 서울시청 경도와 위도
        bounds: undefined as unknown as LatLngBounds,
        zoomlevel: 13,
        mapWidth: '100%',
        mapHeight: '75%',
        mType: undefined,
        point: undefined
    });
    

    useEffect(() => {
    
        // 배경지도 초기 세팅!
        const tile_layer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
        const map = new L.Map(mapRef.current||'');
        map.setView(L.latLng(mapInfo.center[0],mapInfo.center[1]), 13);
        map.addLayer(tile_layer);

        // 지도 움직일때마다 중심과 zoomlevel 가져옴
        map.addEventListener("dragend", (e): void => {
            const dragMap = e.target;
            setMapInfo(prev => ({ ...prev, center: [dragMap.getCenter().lat,dragMap.getCenter().lng]}));
            setMapInfo(prev => ({ ...prev, bounds: dragMap.getBounds()}));
            setMapInfo(prev => ({ ...prev, zoomlevel: dragMap.getZoom()}));
            setMapInfo(prev => ({ ...prev, mType: "dragend"}));
        });
        map.addEventListener("click", (e): void =>{
            const clickMap = e.target;
            console.log(e);
            setMapInfo(prev => ({ ...prev, center: [clickMap.getCenter().lat,clickMap.getCenter().lng]}));
            setMapInfo(prev => ({ ...prev, point: [e.latlng.lat, e.latlng.lng]}));
            setMapInfo(prev => ({ ...prev, bounds: clickMap.getBounds()}));
            setMapInfo(prev => ({ ...prev, zoomlevel: clickMap.getZoom()}));
            setMapInfo(prev => ({ ...prev, mType: "click"}));
        })

        // 지도 객체 저장
        setMapObject(map);

        return () => {
            map.remove();
        };
        
    }, []); 

    // 없으면 버튼 두번째 클릭부터 객체에 지도가 없어짐.
    useEffect(() => {
        if(mapObject){
            console.log("map start!");
        }
    }, [mapObject]); 

    return (
        <div style={{width: '100%', height: '100%'}}>
            <div ref={mapRef} style={{width: mapInfo.mapWidth, height: mapInfo.mapHeight}}></div>
            <div style={{width: '100%', height: '25%', backgroundColor: '#fff', zIndex: 10}}>
                <p style={{margin: 0}}>{mapInfo.mType}</p>
                <p style={{margin: 5}}><span>중심점 : </span>{ mapInfo.center.toString() }</p>
                <p style={{margin: 5}}><span>테두리 : </span> {mapInfo.bounds ? mapInfo.bounds.toBBoxString() : ''}</p>
                {mapInfo.mType === "click"? 
                    <p style={{margin: 5}}><span>클릭 위치 : </span> {mapInfo.point}</p> 
                    : ''
                }
            </div>
        </div>
    )
}
