/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import useLatestPositionStore from "./latest-position.store";


export default function OLMapBasic(){
  const map_ref = useRef<HTMLDivElement>(null)
  const lastPositionStore = useLatestPositionStore ();

  useEffect(() => {
      const tile_layer = new TileLayer({
        source: new XYZ({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        }),
      });

      const {center, zoom} = lastPositionStore;

      const view = new View({
          center,
          zoom
      })

      view.on('change:center', () => {
        lastPositionStore.setCenter(view.getCenter() as [number, number]);
      });

      view.on('change:resolution', () => {
        lastPositionStore.setZoom(view.getZoom()||10);
      });

      const map = new Map();

      map.setLayers([tile_layer]);
      map.setView(view);
      map.setTarget(map_ref.current || '')

      return () => {
          map.setTarget('');
      };

  }, []);

    return <div ref={map_ref} style={{width: '100%', height: '100%'}}/>
}