import React from "react";
import { Box, Paper, Toolbar, Typography } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax, import/no-duplicates
import OLDrawAndModifyFeaturesCode from "!!raw-loader!./code/DrawAndModifyFeatures/OLDrawAndModifyFeatures";
import OLDrawAndModifyFeatures from "./code/DrawAndModifyFeatures/OLDrawAndModifyFeatures";

const Component = () => {
  return (
    <SyntaxHighlighter
      language="typescript"
      style={oneDark}
      wrapLongLines={true}
      lineProps={{ style: { height: "40vh" } }}
    >
      {OLDrawAndModifyFeaturesCode}
    </SyntaxHighlighter>
  );
};

const install_code = `# 필요한 라이브러리 설치
yarn add ol
yarn add ol-ext
yarn add -D @types/ol-ext@npm:@siedlerchr/types-ol-ext
`

const InstallCode = ()=>{
  return (
    <SyntaxHighlighter
      language="typescript"
      style={oneDark}
      wrapLongLines={true}
    >
      {install_code}
    </SyntaxHighlighter>
  );
}

export default function OLDrawAndModifyPage() {
  return (
    <Paper sx={{ height: "100vh", width: "100%", px: 1 }}>
      <Toolbar />
      <Typography component="h1" variant="h4" sx={{ py: 2 }}>
        Openlayers / EXT / Vector 작업도구
      </Typography>

      <Box sx={{ height: "40vh", width: "100%" }}>
        <OLDrawAndModifyFeatures />
      </Box>

      <Box sx={{ }}>
        <Typography variant="h6">설치</Typography>
        <InstallCode />
      </Box>

      <Box sx={{  }}>
        <Typography variant="h6">코드</Typography>
        <Component />
      </Box>

    </Paper>
  );
}
