/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
// import { OSM } from "ol/source";
import { XYZ,  } from "ol/source";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { GeoJSON } from 'ol/format';
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import Circle from "ol/style/Circle";

import "ol/ol.css"
import "ol-ext/dist/ol-ext.css"
import Bar from "ol-ext/control/Bar";
import EditBar from "ol-ext/control/EditBar";
import UndoRedo from "ol-ext/interaction/UndoRedo";
// import Toggle from "ol-ext/control/Toggle";
import Button from "ol-ext/control/Button";
// import FillAttribute from "ol-ext/interaction/FillAttribute";
import './font-awesome.min.css';



const vector = new VectorLayer({
  source: new VectorSource({
    url: '/geojsons/polygon_4326.geojson',
    format: new GeoJSON()
  }),
  style (f) {
    return new Style({
      image: new Circle({
        radius: 5,
        stroke: new Stroke({ width: 1.5, color: f.get('color') || [255,128,0] }),
        fill: new Fill({ color: (f.get('color') || [255,128,0]).concat([.3]) })
      }),
      stroke: new Stroke({ width: 2.5, color: f.get('color') || [255,128,0] }),
      fill: new Fill({ color: (f.get('color') || [255,128,0]).concat([.3]) })
    })
  }
});


export default function OLDrawAndModifyFeatures(){
  const map = useRef<Map>(new Map())
  const mapRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    
      const tile_layer = new TileLayer({
        source: new XYZ({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        }),
      });

      const view = new View({
          center: fromLonLat([126.412523513, 33.490032308]), // 서울시청 경도와 위도
          zoom: 14,
      })

      map.current.setLayers([tile_layer, vector]);
      map.current.setView(view);
      map.current.setTarget(mapRef.current || '')

      const mainbar = new Bar();
      map.current.addControl(mainbar);
      // Editbar
      const editbar = new EditBar ({
        source: vector.getSource(),
        interactions: { Info: false }
      });
      mainbar.addControl(editbar);


      // Undo redo interaction
      const undoInteraction = new UndoRedo();
      map.current.addInteraction(undoInteraction);

      const bar = new Bar({ 
        group: true,
        controls: [
          new Button({
            html: '<i class="fa fa-undo" ></i>',
            title: 'undo...',
            handleClick() {
              undoInteraction.undo();
            }
          }),
          new Button({
            html: '<i class="fa fa-repeat" ></i>',
            title: 'redo...',
            handleClick() {
              undoInteraction.redo();
            }
          })
        ]
      });
      mainbar.addControl(bar);

      return () => {
          map.current.setTarget('');
      };
  }, []);

    return <div style={{width: '100%', height: '90%'}}>
      <div ref={mapRef} style={{width: '100%', height: '100%'}}/>
    </div>
}

