/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";

export default function LFDrawMap() {
  const mapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    
    const center = L.latLng(37.5666612, 126.9783785); // 서울시청 경도와 위도
    
    const tile_layer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    })
    
    const map = new L.Map(mapRef.current||'');
    map.setView(center, 13);
    map.addLayer(tile_layer)

    const drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);
    const drawControl = new L.Control.Draw({
      // edit: {
      //     featureGroup: drawnItems
      // }
      position: 'topright'
    });

    map.addControl(drawControl);
    map.on(L.Draw.Event.CREATED, function (event) {
      console.log(event);
      console.log(event.target);
      const {layer} = event;
      drawnItems.addLayer(layer);
    });

    return () => {
      map.remove();
    };
  }, []);

  return <div ref={mapRef} style={{width: '100%', height: '100%'}}/>
}
