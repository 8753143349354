/* eslint-disable camelcase */
import React, { useEffect, useRef, useMemo } from "react";
import { Collection, Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import {platformModifierKeyOnly} from 'ol/events/condition.js';
import { DragBox, Select } from "ol/interaction";
import { Fill, Stroke, Style } from "ol/style";
import VectorSource from "ol/source/Vector";
import { GeoJSON } from 'ol/format';
import VectorLayer from "ol/layer/Vector";
import { getWidth } from "ol/extent";

export default function BoxSelection(){
  const map_ref = useRef<HTMLDivElement>(null)
  const selectedFeatures = useRef<Collection<Feature>>(new Collection<Feature>());

  const vectorSource = useMemo(() => new VectorSource({
    url: 'https://openlayers.org/data/vector/ecoregions.json',
    format: new GeoJSON(),
  }), []);

  const dragBox = useMemo(() => new DragBox({
    condition: platformModifierKeyOnly,
  }), []);

  const selectedStyle = useMemo(() => new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.6)',
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 0.7)',
      width: 2,
    }),
  }), []);

  // a normal select interaction to handle click
  const select = useMemo(() => { 
    return new Select({
      style: (feature) => {
        const color = feature?.get('COLOR_BIO') || '#eeeeee';
        if(selectedStyle.getFill()!==null){
          selectedStyle.getFill()!.setColor(color);
        }
        return selectedStyle;
      },
    })
  }, [selectedStyle]);


  useEffect(() => {
      const tile_layer = new TileLayer({
        source: new XYZ({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        }),
      });

      const view =  new View({
        center: [0, 0],
        zoom: 2,
        constrainRotation: 16,
      });

      const map = new Map();
      const style = new Style({
        fill: new Fill({
          color: '#eeeeee',
        }),
      });
      map.setLayers([tile_layer]);
      map.addLayer(new VectorLayer({
        source: vectorSource,
        background: '#1a2b39',
          style: (feature) => {
          const color = feature.get('COLOR_BIO') || '#eeeeee';
          style.getFill()!.setColor(color);
          return style;
        },
      }),);
      map.setView(view);
      map.setTarget(map_ref.current || '')

      map.addInteraction(dragBox);
      map.addInteraction(select);

      selectedFeatures.current = select.getFeatures();

      dragBox.on('boxend', () => {
        const boxExtent = dragBox.getGeometry().getExtent();
      
        // if the extent crosses the antimeridian process each world separately
        const worldExtent = map.getView().getProjection().getExtent();
        const worldWidth = getWidth(worldExtent);
        const startWorld = Math.floor((boxExtent[0] - worldExtent[0]) / worldWidth);
        const endWorld = Math.floor((boxExtent[2] - worldExtent[0]) / worldWidth);
      
        for (let world = startWorld; world <= endWorld; world += 1) {
          const left = Math.max(boxExtent[0] - world * worldWidth, worldExtent[0]);
          const right = Math.min(boxExtent[2] - world * worldWidth, worldExtent[2]);
          const extent = [left, boxExtent[1], right, boxExtent[3]];
          const boxFeatures = vectorSource
            .getFeaturesInExtent(extent)
            .filter(
              (feature) =>
                !selectedFeatures.current.getArray().includes(feature) &&
                feature.getGeometry()!.intersectsExtent(extent),
            );
      
          // features that intersect the box geometry are added to the
          // collection of selected features
      
          // if the view is not obliquely rotated the box geometry and
          // its extent are equalivalent so intersecting features can
          // be added directly to the collection
          const rotation = map.getView().getRotation();
          const oblique = rotation % (Math.PI / 2) !== 0;
      
          // when the view is obliquely rotated the box extent will
          // exceed its geometry so both the box and the candidate
          // feature geometries are rotated around a common anchor
          // to confirm that, with the box geometry aligned with its
          // extent, the geometries intersect
          if (oblique) {
            const anchor = [0, 0];
            const geometry = dragBox.getGeometry().clone();
            geometry.translate(-world * worldWidth, 0);
            geometry.rotate(-rotation, anchor);
            const extent = geometry.getExtent();
            boxFeatures.forEach( (feature) => {
              const geometry = feature.getGeometry()!.clone();
              geometry.rotate(-rotation, anchor);
              if (geometry.intersectsExtent(extent)) {
                selectedFeatures.current.push(feature);
              }
            });
          } else {
            selectedFeatures.current.extend(boxFeatures);
          }
        }
      });
      
      // clear selection when drawing a new box and when clicking on the map
      dragBox.on('boxstart', () => {
        selectedFeatures.current.clear();
      });

      return () => {
          map.setTarget('');
      };

  }, []);

    return <div ref={map_ref} style={{width: '100%', height: '100%'}}/>
}