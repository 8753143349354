/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import {LeafletMap, InnopamMap} from "@innopam/map";
import "leaflet/dist/leaflet.css";

export default function IPSyncLFMap(){
  const map_ref1 = useRef<HTMLDivElement>(null)
  const map1 = useRef<InnopamMap>()

  const map_ref2 = useRef<HTMLDivElement>(null)
  const map2 = useRef<InnopamMap>()

  useEffect(() => {
    if (!map1.current) {
      // eslint-disable-next-line no-new
      map1.current = new LeafletMap(map_ref1.current || '');
      map1.current?.addXYZLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png');
      map1.current?.setViewByLatLng([37.5666612, 126.9783785], 14);
    }

    if (!map2.current) {
      // eslint-disable-next-line no-new
      map2.current = new LeafletMap(map_ref2.current || '');
      map2.current?.addXYZLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png');
      map2.current?.setViewByLatLng([37.5666612, 126.9783785], 14);
    }

    map1.current?.sync(map2.current);

  }, []);

  return <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row'}}>
    <div ref={map_ref1} style={{width: '50%', height: '100%'}}/>
    <div ref={map_ref2} style={{width: '50%', height: '100%'}}/>
    <div style={{}}>
      <button onClick={() => map1.current?.sync(map2.current!)}>sync</button>
      <button onClick={() => map1.current?.unsync(map2.current!)}>unsync</button>
    </div>
  </div>

}